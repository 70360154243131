.btn[data-v-abe060f0] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
[data-v-abe060f0] .deparmentLayout .avue-crud .el-table {
  height: calc(100vh - 324px) !important;
  max-height: calc(100vh - 324px) !important;
}
[data-v-abe060f0] .el-tree {
  height: calc(100vh - 333px) !important;
}