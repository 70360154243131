.leftTree[data-v-15e13bfe] {
  width: 280px;
  border-right: 1px solid #ccc;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: 0;
          flex-direction: 0;
}
[data-v-15e13bfe] .leftTree .common_tree_handle .el-tree {
  height: calc(100vh - 237px) !important;
}
.el-scrollbar[data-v-15e13bfe] {
  height: 100%;
}
.box .el-scrollbar__wrap[data-v-15e13bfe] {
  overflow: scroll;
}
.roleDialog[data-v-15e13bfe] .el-dialog__body {
  padding: 10px 0 !important;
}