.bottonmBtn[data-v-ddaf6c1a] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-top: 12px;
}
[data-v-ddaf6c1a] .el-table .el-table__cell.is-center {
  vertical-align: top !important;
}
[data-v-ddaf6c1a] .el-table th.el-table__cell.is-leaf {
  vertical-align: top !important;
}